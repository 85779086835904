<template>
  <nuxt-link v-if="availableLocales.length === 1" :to="switchLocalePath(availableLocales[0].slug)" class="switch-language">
    {{ availableLocales[0].slug[0].toUpperCase() + availableLocales[0].slug.substring(1) }}
  </nuxt-link>
</template>

<script>
import {Language} from '@/models/language';

export default {
  data() {
    return {
      languages: [],
    }
  },
  computed: {
    availableLocales() {
      return this.languages.filter(x => x.slug !== this.$i18n.locale && this.$i18n.locales.map(y => y.code).includes(x.slug));
    },
  },
  async fetch() {
    this.languages = await Language.config({count_loading: true}).get();
  },
}
</script>
