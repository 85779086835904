<i18n lang="yaml">
ru:
  menu: 'Меню'
en:
  menu: 'Menu'
</i18n>

<template>
  <div class="header-item">
    <div v-if="naviItem.header_submenu.length && naviItem.desktop_show" class="menu menu-c"
         @click="closeTree"
         v-bind:class="{'show-menu': showMenu}" v-click-outside="closeMenu">
      <span>{{ naviItem.title }}</span>
      <i class="svg-icon arrow"></i>
      <Transition name="fade">
        <div v-if="showMenu" class="header-menu">
          <div>
            <site-link v-for="item in naviItem.header_submenu" :key="item.id" v-if="item.desktop_show"
                       :link-item="item"/>
          </div>
        </div>
      </Transition>
    </div>
    <div v-else-if="!naviItem.header_submenu.length && naviItem.desktop_show" class="menu">
      <site-link :link-item="naviItem"/>
    </div>
  </div>


</template>

<script>
import SiteLink from '@/components/site-link';

export default {
  components: {
    SiteLink,
  },
  props: ['naviItem'],
  data() {
    return {
      showMenu: false,
      showMenuMobile: false,
    }
  },
  methods: {
    closeMenu() {
      if (this.showMenu) {
        this.showMenu = !this.showMenu;
      }
    },
    closeMenuMobile() {
      if (this.showMenuMobile) {
        this.showMenuMobile = !this.showMenuMobile;
      }
    },
    closeTree() {
      this.showMenu = !this.showMenu
      this.$emit('close-tree');
    },
    closeTreeMobile() {
      this.showMenuMobile = !this.showMenuMobile
      this.$emit('close-tree');
    }
  },
}
</script>
