import {Product} from '@/models/product';

export const state = () => ({
  loading: 0,
  buildVersion: 0,
  hideScrollbar: false,
  cartExists: {
    'count': 0,
    'express': false,
  },
  priceRequestExists: 0,
  favouriteProductsExists: false,
  scripts: [],
});

export const mutations = {
  addLoading(state) {
    state.loading++;
  },
  removeLoading(state) {
    state.loading--;
  },
  setBuildVersion(state, value) {
    state.buildVersion = value;
  },
  setHideScrollbar(state, value) {
    state.hideScrollbar = value;
  },
  setCartExists(state, cartExists) {
    state.cartExists = cartExists;
  },
  setPriceRequestExists(state, priceRequestExists) {
    state.priceRequestExists = priceRequestExists;
  },
  setFavouriteProductsExists(state, favouriteProductsExists) {
    state.favouriteProductsExists = favouriteProductsExists;
  },
  setScripts(state, scripts){
    state.scripts = scripts;
  },
};

export const actions = {
  addLoading(vuexContext) {
    vuexContext.commit('addLoading');
  },
  removeLoading(vuexContext) {
    vuexContext.commit('removeLoading');
  },
  setBuildVersion(vuexContext, callback = () => {}) {
    this.$axios.$get('/last-build-version/')
      .then((response) => vuexContext.commit('setBuildVersion', response))
      .catch(() => {})
      .finally(() => callback());
  },
  hideScrollbar(vuexContext) {
    vuexContext.commit('setHideScrollbar', true);
  },
  showScrollbar(vuexContext) {
    vuexContext.commit('setHideScrollbar', false);
  },
  setCartExists(vuexContext, callback = () => {}) {
    this.$axios.$get('/order/user-order/check-cart-exists/')
      .then((response) => vuexContext.commit('setCartExists', response))
      .catch((e) => vuexContext.commit('setCartExists', {'count': 0, 'express': false}))
      .finally(() => callback());
  },
  setPriceRequestExists(vuexContext) {
    this.$axios.$get('/order/price-request/check-price-request-exists/')
      .then((response) => vuexContext.commit('setPriceRequestExists', response))
      .catch((e) => vuexContext.commit('setPriceRequestExists', 0));
  },
  setFavouriteProductsExists(vuexContext, callback = () => {}) {
    Product.params({page_size: 1, favourites: 1}).first()
      .then((response) => vuexContext.commit('setFavouriteProductsExists', response.count > 0))
      .finally(() => callback());
  },
  getScripts(vuexContext, callback = () => {}) {
    this.$axios.$get('/scripts/')
      .then((response) => vuexContext.commit('setScripts', response))
      .finally(() => callback());
  },
};

export const getters = {
  loading(state) {
    return state.loading;
  },
  buildVersion(state) {
    return state.buildVersion;
  },
  hideScrollbar(state) {
    return state.hideScrollbar;
  },
  cartExists(state) {
    return state.cartExists;
  },
  priceRequestExists(state) {
    return state.priceRequestExists;
  },
  favouriteProductsExists(state) {
    return state.favouriteProductsExists;
  },
  scripts(state) {
    return state.scripts;
  },
};
