import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8564b66a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _11106992 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _4e5b6556 = () => interopDefault(import('../pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _473ffeb2 = () => interopDefault(import('../pages/chat/index.vue' /* webpackChunkName: "pages/chat/index" */))
const _f215703c = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _449e186d = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _5bd561f7 = () => interopDefault(import('../pages/password-reset/index.vue' /* webpackChunkName: "pages/password-reset/index" */))
const _053d7484 = () => interopDefault(import('../pages/price-request.vue' /* webpackChunkName: "pages/price-request" */))
const _f0727a36 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _102da48c = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _4af99942 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _37ffb9a4 = () => interopDefault(import('../pages/products/favourites.vue' /* webpackChunkName: "pages/products/favourites" */))
const _754a89e2 = () => interopDefault(import('../pages/profile/orders.vue' /* webpackChunkName: "pages/profile/orders" */))
const _030a4776 = () => interopDefault(import('../pages/profile/price-requests.vue' /* webpackChunkName: "pages/profile/price-requests" */))
const _db490adc = () => interopDefault(import('../pages/profile/reports.vue' /* webpackChunkName: "pages/profile/reports" */))
const _8061bb28 = () => interopDefault(import('../pages/profile/search-history.vue' /* webpackChunkName: "pages/profile/search-history" */))
const _7720b5f0 = () => interopDefault(import('../pages/profile/user.vue' /* webpackChunkName: "pages/profile/user" */))
const _12745e8a = () => interopDefault(import('../pages/search/oem/index.vue' /* webpackChunkName: "pages/search/oem/index" */))
const _4087091c = () => interopDefault(import('../pages/search/oem/_id.vue' /* webpackChunkName: "pages/search/oem/_id" */))
const _007bf4cc = () => interopDefault(import('../pages/chat/_id.vue' /* webpackChunkName: "pages/chat/_id" */))
const _007d5bd6 = () => interopDefault(import('../pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _3444d7d3 = () => interopDefault(import('../pages/product/_id.vue' /* webpackChunkName: "pages/product/_id" */))
const _fc8c95b0 = () => interopDefault(import('../pages/catalog/_slug/obzor.vue' /* webpackChunkName: "pages/catalog/_slug/obzor" */))
const _c219e4c6 = () => interopDefault(import('../pages/password-reset/_uid/_token.vue' /* webpackChunkName: "pages/password-reset/_uid/_token" */))
const _8242783c = () => interopDefault(import('../pages/catalog/_.vue' /* webpackChunkName: "pages/catalog/_" */))
const _45e6fd1c = () => interopDefault(import('../pages/profile/_.vue' /* webpackChunkName: "pages/profile/_" */))
const _f9277350 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en/",
    component: _8564b66a,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/ru/",
    component: _8564b66a,
    pathToRegexpOptions: {"strict":true},
    name: "index___ru"
  }, {
    path: "/en/cart/",
    component: _11106992,
    pathToRegexpOptions: {"strict":true},
    name: "cart___en"
  }, {
    path: "/en/catalog/",
    component: _4e5b6556,
    pathToRegexpOptions: {"strict":true},
    name: "catalog___en"
  }, {
    path: "/en/chat/",
    component: _473ffeb2,
    pathToRegexpOptions: {"strict":true},
    name: "chat___en"
  }, {
    path: "/en/login/",
    component: _f215703c,
    pathToRegexpOptions: {"strict":true},
    name: "login___en"
  }, {
    path: "/en/news/",
    component: _449e186d,
    pathToRegexpOptions: {"strict":true},
    name: "news___en"
  }, {
    path: "/en/password-reset/",
    component: _5bd561f7,
    pathToRegexpOptions: {"strict":true},
    name: "password-reset___en"
  }, {
    path: "/en/price-request/",
    component: _053d7484,
    pathToRegexpOptions: {"strict":true},
    name: "price-request___en"
  }, {
    path: "/en/profile/",
    component: _f0727a36,
    pathToRegexpOptions: {"strict":true},
    name: "profile___en"
  }, {
    path: "/en/register/",
    component: _102da48c,
    pathToRegexpOptions: {"strict":true},
    name: "register___en"
  }, {
    path: "/en/search/",
    component: _4af99942,
    pathToRegexpOptions: {"strict":true},
    name: "search___en"
  }, {
    path: "/ru/cart/",
    component: _11106992,
    pathToRegexpOptions: {"strict":true},
    name: "cart___ru"
  }, {
    path: "/ru/catalog/",
    component: _4e5b6556,
    pathToRegexpOptions: {"strict":true},
    name: "catalog___ru"
  }, {
    path: "/ru/chat/",
    component: _473ffeb2,
    pathToRegexpOptions: {"strict":true},
    name: "chat___ru"
  }, {
    path: "/ru/login/",
    component: _f215703c,
    pathToRegexpOptions: {"strict":true},
    name: "login___ru"
  }, {
    path: "/ru/news/",
    component: _449e186d,
    pathToRegexpOptions: {"strict":true},
    name: "news___ru"
  }, {
    path: "/ru/password-reset/",
    component: _5bd561f7,
    pathToRegexpOptions: {"strict":true},
    name: "password-reset___ru"
  }, {
    path: "/ru/price-request/",
    component: _053d7484,
    pathToRegexpOptions: {"strict":true},
    name: "price-request___ru"
  }, {
    path: "/ru/profile/",
    component: _f0727a36,
    pathToRegexpOptions: {"strict":true},
    name: "profile___ru"
  }, {
    path: "/ru/register/",
    component: _102da48c,
    pathToRegexpOptions: {"strict":true},
    name: "register___ru"
  }, {
    path: "/ru/search/",
    component: _4af99942,
    pathToRegexpOptions: {"strict":true},
    name: "search___ru"
  }, {
    path: "/en/products/favourites/",
    component: _37ffb9a4,
    pathToRegexpOptions: {"strict":true},
    name: "products-favourites___en"
  }, {
    path: "/en/profile/orders/",
    component: _754a89e2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-orders___en"
  }, {
    path: "/en/profile/price-requests/",
    component: _030a4776,
    pathToRegexpOptions: {"strict":true},
    name: "profile-price-requests___en"
  }, {
    path: "/en/profile/reports/",
    component: _db490adc,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reports___en"
  }, {
    path: "/en/profile/search-history/",
    component: _8061bb28,
    pathToRegexpOptions: {"strict":true},
    name: "profile-search-history___en"
  }, {
    path: "/en/profile/user/",
    component: _7720b5f0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-user___en"
  }, {
    path: "/en/search/oem/",
    component: _12745e8a,
    pathToRegexpOptions: {"strict":true},
    name: "search-oem___en"
  }, {
    path: "/ru/products/favourites/",
    component: _37ffb9a4,
    pathToRegexpOptions: {"strict":true},
    name: "products-favourites___ru"
  }, {
    path: "/ru/profile/orders/",
    component: _754a89e2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-orders___ru"
  }, {
    path: "/ru/profile/price-requests/",
    component: _030a4776,
    pathToRegexpOptions: {"strict":true},
    name: "profile-price-requests___ru"
  }, {
    path: "/ru/profile/reports/",
    component: _db490adc,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reports___ru"
  }, {
    path: "/ru/profile/search-history/",
    component: _8061bb28,
    pathToRegexpOptions: {"strict":true},
    name: "profile-search-history___ru"
  }, {
    path: "/ru/profile/user/",
    component: _7720b5f0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-user___ru"
  }, {
    path: "/ru/search/oem/",
    component: _12745e8a,
    pathToRegexpOptions: {"strict":true},
    name: "search-oem___ru"
  }, {
    path: "/en/search/oem/:id/",
    component: _4087091c,
    pathToRegexpOptions: {"strict":true},
    name: "search-oem-id___en"
  }, {
    path: "/ru/search/oem/:id/",
    component: _4087091c,
    pathToRegexpOptions: {"strict":true},
    name: "search-oem-id___ru"
  }, {
    path: "/en/chat/:id/",
    component: _007bf4cc,
    pathToRegexpOptions: {"strict":true},
    name: "chat-id___en"
  }, {
    path: "/en/news/:id/",
    component: _007d5bd6,
    pathToRegexpOptions: {"strict":true},
    name: "news-id___en"
  }, {
    path: "/en/product/:id?/",
    component: _3444d7d3,
    pathToRegexpOptions: {"strict":true},
    name: "product-id___en"
  }, {
    path: "/ru/chat/:id/",
    component: _007bf4cc,
    pathToRegexpOptions: {"strict":true},
    name: "chat-id___ru"
  }, {
    path: "/ru/news/:id/",
    component: _007d5bd6,
    pathToRegexpOptions: {"strict":true},
    name: "news-id___ru"
  }, {
    path: "/ru/product/:id?/",
    component: _3444d7d3,
    pathToRegexpOptions: {"strict":true},
    name: "product-id___ru"
  }, {
    path: "/en/catalog/:slug/obzor/",
    component: _fc8c95b0,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-slug-obzor___en"
  }, {
    path: "/ru/catalog/:slug/obzor/",
    component: _fc8c95b0,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-slug-obzor___ru"
  }, {
    path: "/en/password-reset/:uid?/:token/",
    component: _c219e4c6,
    pathToRegexpOptions: {"strict":true},
    name: "password-reset-uid-token___en"
  }, {
    path: "/ru/password-reset/:uid?/:token/",
    component: _c219e4c6,
    pathToRegexpOptions: {"strict":true},
    name: "password-reset-uid-token___ru"
  }, {
    path: "/en/catalog/*/",
    component: _8242783c,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-all___en"
  }, {
    path: "/en/profile/*/",
    component: _45e6fd1c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-all___en"
  }, {
    path: "/ru/catalog/*/",
    component: _8242783c,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-all___ru"
  }, {
    path: "/ru/profile/*/",
    component: _45e6fd1c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-all___ru"
  }, {
    path: "/en/*/",
    component: _f9277350,
    pathToRegexpOptions: {"strict":true},
    name: "all___en"
  }, {
    path: "/ru/*/",
    component: _f9277350,
    pathToRegexpOptions: {"strict":true},
    name: "all___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
