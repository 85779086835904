import Model from './base';

export class MenuItem extends Model {
  defaultAttributes() {
    return {
      header_submenu: [],
      footer_submenu: [],
      link_document: null,
      link_external: null,
      link_page: null,
      desktop_show: false,
      mobile_show: false,
      phone: false,
      sort_order: null,
      target_blank: false,
      title: null,
      show_categories: false,
    }
  }
}

export class Header extends MenuItem {
  resource() {
    return 'header-menu';
  }
}

export class Footer extends MenuItem {
  resource() {
    return 'footer-menu';
  }
}

export class FooterText extends Model {
  resource() {
    return 'footer-text';
  }

  defaultAttributes() {
    return {
      copyright: null,
      text: null,
    }
  }
}
