<i18n lang="yaml">
ru:
  menu: 'Меню'
en:
  menu: 'Menu'
</i18n>

<template>
  <div v-if="naviItem.header_submenu.length && naviItem.mobile_show"
       @click="closeTreeMobile" class="menu menu-c header-item-mobile"
       v-bind:class="{'show-menu': showMenuMobile}"
       v-click-outside="closeMenuMobile"
  >
    <i class="svg-icon dots"></i>
    <Transition name="fade">
      <div v-if="showMenuMobile" class="container header-menu">
        <div>
          <div class="menu-header-close">
            <h1>{{ $t('menu') }}</h1>
            <div @click="closeMenu">
              <i class="svg-icon cross"></i>
            </div>
          </div>
          <site-link v-for="item in naviItem.header_submenu" :key="item.id" v-if="item.mobile_show"
                     :link-item="item"/>
        </div>
      </div>
    </Transition>

  </div>


</template>

<script>
import SiteLink from '@/components/site-link';

export default {
  components: {
    SiteLink,
  },
  props: ['naviItem'],
  data() {
    return {
      showMenu: false,
      showMenuMobile: false,
    }
  },
  methods: {
    closeMenu() {
      if (this.showMenu) {
        this.showMenu = !this.showMenu;
      }
    },
    closeMenuMobile() {
      if (this.showMenuMobile) {
        this.showMenuMobile = !this.showMenuMobile;
      }
    },
    closeTree() {
      this.showMenu = !this.showMenu
      this.$emit('close-tree');
    },
    closeTreeMobile() {
      this.showMenuMobile = !this.showMenuMobile
      this.$emit('close-tree');
    }
  },
}
</script>
