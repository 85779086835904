<i18n lang="yaml">
ru:
  search_by_code: 'Найти по номеру детали'
  enter_code: 'Введите номер детали'
  not_found: 'Ничего не найдено.'
  all_results: 'Все результаты'
en:
  search_by_code: 'Search by product code'
  enter_code: 'Enter product code'
  not_found: 'Not found.'
  all_results: 'All results'
</i18n>

<template>
  <div>
    <v-select @search="fetchProducts" :options="productsOptions" :filterable="false" label="code"
              :select-on-key-codes="[]" :map-keydown="handlers" @input="searchProduct" :clearable="false"
              :placeholder="$t('search_by_code')" :clearSearchOnBlur="() => false" ref="vSelect">
      <template #no-options="{search, searching, loading}">
        <span v-if="!search.length">{{ $t('enter_code') }}</span>
        <span v-else-if="!productsOptions.length">{{ $t('not_found') }}</span>
      </template>
      <template #option="{id, code, slug, name}">
        <span>{{ name }}</span>
      </template>
      <template #list-footer="{search, loading, searching, filteredOptions}">
        <li class="vs__dropdown-option"
            v-if="!loading && search.length && products && products.count > pageSize">
          <span @click.prevent="searchButtonProduct">{{ $t('all_results') }}</span>
        </li>
      </template>
      <template #spinner="{ loading }">
        <span></span>
      </template>
      <template #open-indicator="{ attributes }">
        <span></span>
      </template>
    </v-select>
    <div class="search-btn">
      <!-- <div @click.prevent="searchButtonProduct" :aria-label="$t('search')"
         :disabled="!searchTerm" :class="{'disabled': !searchTerm}"><i
          class="svg-icon search-icon"></i></div> -->
      <a href="#" @click.prevent="searchButtonProduct" :aria-label="$t('search')"
         :disabled="!searchTerm" :class="{'disabled': !$refs.vSelect?.search}"><i
          class="svg-icon search-icon"></i></a>
    </div>

    <a href="#" class="cross-icon" v-if="showClearButton" @click.prevent="clearInput" :aria-label="$t('clear')">
      <i class="svg-icon cross"></i>
    </a>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import {Product} from '@/models/product';

export default {
  components: {
    vSelect,
  },
  name: 'ProductsSearchInput',
  data() {
    return {
      products: {},
      pageSize: 5,
      searchTerm: '',
    }
  },
  computed: {
    showClearButton() {
      return !!(this.$refs.vSelect && this.$refs.vSelect.search);
    },
    productsOptions() {
      return this.products.results ? this.products.results.map(x => ({
        'id': x.id,
        'code': x.code,
        'slug': x.slug,
        'name': this.getProductLocaleData(x).name,
      })) : [];
    },
  },
  methods: {
    getProductLocaleData(product) {
      return product?.product_translations?.find(x => x.language.slug === this.$i18n.locale) || {};
    },
    fetchProducts(search, loading) {
      if (search.length) {
        this.searchTerm = search;
        loading(true);
        Product.params({search: search, page_size: this.pageSize}).first()
          .then((response) => this.products = response)
          .finally(() => loading(false));
      } else {
        this.products = {};
      }
    },
    searchProduct(search) {
      if (search) {
        this.$router.push({
          path: this.localePath(`/product/${search.slug}/`)
          // path: this.localePath(`/product/${search.id}/`)
        });
        this.$emit('search-input');
      }
    },
    searchButtonProduct() {
      if (this.searchTerm) {
        this.$router.push({
          path: this.localePath('/search/'),
          query: {'search': this.searchTerm}
        });
        this.$refs.vSelect.searchEl.blur();
      }
    },
    handlers(map, vm) {
      return {
        ...map,
        13: (e) => {
          e.preventDefault();
          this.searchButtonProduct();
        },
      }
    },
    clearInput() {
      this.$refs.vSelect.search = '';
      this.fetchProducts(false)
    }
  }
}
</script>
